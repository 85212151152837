import React from "react"
import axios from "axios"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import InputBase from "@material-ui/core/InputBase"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined"
import CircularProgress from "@material-ui/core/CircularProgress"

import handleError from "../../../helperFunc/handleError"
import handleSuccess from "../../../helperFunc/handleSuccess"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  titleText: {
    fontWeight: 500,
    marginTop: "0.5rem",
    marginBottom: "0.25rem",
    marginLeft: "0.5rem",
    lineHeight: "1rem",
    fontSize: "1rem",
    textAlign: "left",
  },

  textField: {
    backgroundColor: `${theme.palette.common.black} !important`,
    borderRadius: "1rem",
    padding: "1rem",
    paddingRight: "1rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    width: "100%",
  },
  butEndIcon: {
    margin: 0,
    padding: 0,
  },
  but: {
    padding: "0.5rem",
    borderRadius: "6rem",
    height: "3rem !important",
    width: "3rem !important",
    minWidth: "3rem !important",
  },

  mainGrid: {
    width: "100%",
  },

  fieldGrid: {
    maxWidth: "30rem",
    width: "calc(100% - 3.5rem)",
    paddingRight: "0.5rem",
  },

  labelBox: {
    marginBottom: "0.25rem",
  },
}))

export default function IpAddSectionField({
  dataIn,
  user,
  dispatchFeedback,
  setSections,
  ind,
  sections,
  section,
  label,
  idLabel,
}) {
  const [value, setValue] = React.useState(dataIn)
  const [isLoading, setIsLoading] = React.useState(false)

  const ipRegExp = new RegExp(
    /^((2[0-5]{2}|1[0-9]{2}|[0-9]{1,2})\.){3}(2[0-5]{2}|1[0-9]{2}|[0-9]{1,2})$/
  )

  const handleChange = event => {
    setValue(event.target.value)
  }

  const handleSend = () => {
    let valueToTest = value.trim()
    let noSpaceValue = valueToTest.replace(/ /g, "")

    if (noSpaceValue.length === 0 || ipRegExp.test(noSpaceValue)) {
      setIsLoading(true)
      axios
        .put(
          process.env.GATSBY_STRAPI_URL + `/api/sections/${section.id}`,
          {
            data: {
              printerIp: noSpaceValue,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${user.jwt}`,
            },
          }
        )
        .then(response => {
          axios
            .get(process.env.GATSBY_STRAPI_URL + `/api/sections`, {
              headers: {
                Authorization: `Bearer ${user.jwt}`,
              },
            })
            .then(res => {
              let sectionsCopy = [...res.data.data]
              sectionsCopy.sort(function (a, b) {
                return parseInt(a.id) - parseInt(b.id)
              })
              setIsLoading(false)
              setSections(sectionsCopy)
              handleSuccess("IP address successfully set", dispatchFeedback)
            })
            .catch(err => {
              console.error(err)
              setIsLoading(false)
              handleError(err, dispatchFeedback)
            })
        })
        .catch(error => {
          setIsLoading(false)
          console.error(error)
          handleError(error, dispatchFeedback)
        })
    } else {
      handleError("Invalid IP address", dispatchFeedback)
    }
  }

  const classes = useStyles()

  return (
    <>
      <Box classes={{ root: classes.labelBox }}>
        <Typography variant="body1">{`${label}`}</Typography>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        classes={{ root: classes.mainGrid }}
      >
        <Grid item classes={{ root: classes.fieldGrid }}>
          <InputBase
            disabled={isLoading}
            className={classes.textField}
            id={`standard-multiline-static-${idLabel}-ip`}
            placeholder="0.0.0.0 - 255.255.255.255"
            value={value}
            onChange={handleChange}
          />
        </Grid>
        <Grid item>
          <Button
            disabled={value === dataIn || isLoading}
            aria-label="done"
            variant="contained"
            color="secondary"
            onClick={handleSend}
            classes={{ endIcon: classes.butEndIcon, root: classes.but }}
            endIcon={
              isLoading ? (
                <CircularProgress color="inherit" size={"1.75rem"} />
              ) : (
                <DoneOutlinedIcon />
              )
            }
          ></Button>
        </Grid>
      </Grid>
    </>
  )
}
