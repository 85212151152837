import React from "react"
import axios from "axios"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import handleError from "../../../../helperFunc/handleError"
import handleSuccess from "../../../../helperFunc/handleSuccess"

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined"
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined"

import { makeStyles, withStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  loadingBox: {
    paddingTop: "0.5rem",

    paddingLeft: "0.5rem",
  },

  labelBox: {
    marginBottom: "0.25rem",
  },
}))

const StyledToggleButtonGroup = withStyles(theme => ({
  root: {
    flexWrap: "wrap",
  },
  grouped: {
    border: "none",

    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
      marginRight: "0.5rem",
    },
  },
}))(ToggleButtonGroup)

const StyledToggleButtonOn = withStyles(theme => ({
  root: {
    backgroundColor: `${theme.palette.common.blackTwo} !important`,
    borderTopRightRadius: "2rem !important",
    borderBottomRightRadius: "2rem !important",
    padding: "1rem",
    height: "2.25rem",
    width: "2.25rem",
  },
  selected: {
    backgroundColor: `${theme.palette.common.midGreen} !important`,
  },
}))(ToggleButton)

const StyledToggleButtonOff = withStyles(theme => ({
  root: {
    backgroundColor: `${theme.palette.common.blackTwo} !important`,
    borderTopLeftRadius: "2rem !important",
    borderBottomLeftRadius: "2rem !important",
    padding: "1rem",
    height: "2.25rem",
    width: "2.25rem",
  },
  selected: {
    backgroundColor: `${theme.palette.common.redSelected} !important`,
  },
}))(ToggleButton)

export default function PrintSwitch({
  dataIn,
  user,
  dispatchFeedback,
  setSections,
  ind,
  sections,
  section,
  label,
}) {
  const [isLoading, setIsLoading] = React.useState(false)

  const handleSelectedChange = (e, change) => {
    if (change !== null) {
      handleChange()
    }
  }

  const handleChange = () => {
    setIsLoading(true)
    axios
      .put(
        process.env.GATSBY_STRAPI_URL + `/api/sections/${section.id}`,
        {
          data: {
            separateItems: !dataIn,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      )
      .then(response => {
        axios
          .get(process.env.GATSBY_STRAPI_URL + `/api/sections`, {
            headers: {
              Authorization: `Bearer ${user.jwt}`,
            },
          })
          .then(res => {
            let sectionsCopy = [...res.data.data]
            sectionsCopy.sort(function (a, b) {
              return parseInt(a.id) - parseInt(b.id)
            })
            setIsLoading(false)
            setSections(sectionsCopy)
            handleSuccess("Printer settings successfully set", dispatchFeedback)
          })
          .catch(err => {
            console.error(err)
            setIsLoading(false)
            handleError(err, dispatchFeedback)
          })
      })
      .catch(error => {
        setIsLoading(false)
        console.error(error)
        handleError(error, dispatchFeedback)
      })
  }

  const classes = useStyles()

  return (
    <>
      <Box classes={{ root: classes.labelBox }}>
        <Typography variant="body1">{`${label}`}</Typography>
      </Box>
      {isLoading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress color="inherit" size={"1.25rem"} />
        </Box>
      ) : (
        <>
          <StyledToggleButtonGroup
            value={dataIn ? "YES" : "NO"}
            exclusive
            onChange={handleSelectedChange}
          >
            <StyledToggleButtonOff size="large" value={"NO"}>
              <ClearOutlinedIcon />
            </StyledToggleButtonOff>
            <StyledToggleButtonOn size="large" value={"YES"}>
              <DoneOutlinedIcon />
            </StyledToggleButtonOn>
          </StyledToggleButtonGroup>
        </>
      )}
    </>
  )
}
