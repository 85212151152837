import * as React from "react"
import axios from "axios"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"

import IpAddField from "./ipAddField"
import IpAddSectionField from "./ipAddSectionField"
import PrintSwitch from "./common/printSwitch"
import MainSetOrdStatSwitch from "./common/mainSetOrdStatSwitch"

import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined"
import CircularProgress from "@material-ui/core/CircularProgress"

import handleError from "../../../helperFunc/handleError"
import handleSuccess from "../../../helperFunc/handleSuccess"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainContainer: {
    width: "100%",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    marginBottom: "0.5rem",
  },
  mainBox: {
    width: "100%",
    paddingTop: "5rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    marginBottom: "0.5rem",
  },

  sectionBox: {
    width: "100%",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
  },

  headingText: {
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    fontWeight: 500,
    overflowWrap: "break-word",
    marginBottom: "0.5rem",
  },
  mainGrid: {
    paddingRight: "2.5rem",
    marginBottom: "0.5rem",
  },
  seceondGrid: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  gridBox: {},
  divider: {
    marginTop: "1rem",
    marginBottom: "1.5rem",
    height: "2px",
  },

  refreshBut: {
    borderRadius: "3rem",
    marginTop: "1rem",

    marginBottom: "0.5rem",
  },
}))

const KitchenSettingsMainInCont = ({
  user,
  kitchenSettings,
  setKitchenSettings,
  sections,
  setSections,
  dispatchFeedback,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const classes = useStyles()

  const handleSendRefrsh = () => {
    axios
      .post(
        process.env.GATSBY_STRAPI_URL + `/api/section/refresh-all-displays`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      )
      .then(response => {
        setIsLoading(false)
        handleSuccess("Refreshed displays successfully", dispatchFeedback)
      })
      .catch(error => {
        setIsLoading(false)
        console.error(error)
        handleError(error, dispatchFeedback)
      })
  }

  return (
    <>
      <Box classes={{ root: classes.mainBox }}>
        <Typography variant="h5" classes={{ root: classes.headingText }}>
          {"Main Kitchen Settings"}
        </Typography>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        classes={{ root: classes.mainContainer }}
      >
        <Grid item classes={{ root: classes.mainGrid }}>
          <IpAddField
            dataIn={kitchenSettings.attributes.mainPrinterIp}
            user={user}
            dispatchFeedback={dispatchFeedback}
            setKitchenSettings={setKitchenSettings}
            label={"Main Printer IP Address"}
            idLabel={"main"}
          ></IpAddField>
        </Grid>
        <Grid item classes={{ root: classes.seceondGrid }}>
          <Button
            startIcon={
              isLoading ? (
                <CircularProgress color="inherit" size={"1.75rem"} />
              ) : (
                <RefreshOutlinedIcon />
              )
            }
            disabled={isLoading}
            size="large"
            variant="contained"
            disableElevation
            classes={{ root: classes.refreshBut }}
            fullWidth
            onClick={handleSendRefrsh}
          >
            {"Refresh All Kitchen Displays"}
          </Button>
        </Grid>
        <Grid item>
          <MainSetOrdStatSwitch
            dataIn={kitchenSettings.attributes.mainMoreActionVisibleDef}
            user={user}
            dispatchFeedback={dispatchFeedback}
            label={`Enable order status button on main screen?`}
            setKitchenSettings={setKitchenSettings}
          />
        </Grid>
      </Grid>
      <Divider classes={{ root: classes.divider }} />

      {sections.map((section, index) => {
        return (
          <Box key={index}>
            <Box classes={{ root: classes.sectionBox }}>
              <Typography variant="h5" classes={{ root: classes.headingText }}>
                {`${section.attributes.name} Section`}
              </Typography>
            </Box>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              classes={{ root: classes.mainContainer }}
            >
              <Grid item classes={{ root: classes.mainGrid }}>
                <IpAddSectionField
                  dataIn={section.attributes.printerIp}
                  user={user}
                  dispatchFeedback={dispatchFeedback}
                  label={`Printer IP Address`}
                  setSections={setSections}
                  sections={sections}
                  ind={index}
                  section={section}
                  idLabel={section.attributes.slug}
                ></IpAddSectionField>
              </Grid>
              <Grid item>
                <PrintSwitch
                  dataIn={section.attributes.separateItems}
                  user={user}
                  dispatchFeedback={dispatchFeedback}
                  label={`Print Items Separately?`}
                  setSections={setSections}
                  sections={sections}
                  ind={index}
                  section={section}
                  idLabel={section.attributes.slug}
                />
              </Grid>
            </Grid>
            <Divider classes={{ root: classes.divider }} />
          </Box>
        )
      })}
    </>
  )
}

export default KitchenSettingsMainInCont
